import api from '@common/api';

export const getContainerGateInWalletCredit = () => {
  return api.get('/convoy/cargocvge/credit');
};

export const getWallet = (data) => {
  return api.get('/convoy/credit', {
    params: {
      ...data,
    },
  });
};

export const getWalletList = (data) => {
  return api.get('/convoy/credit/recharge', {
    params: {
      ...data,
    },
  });
};

export const getConsumeList = (data) => {
  return api.get('/convoy/credit/consume', {
    params: {
      ...data,
    },
  });
};

export const lookWallet = (id) => {
  return api.get(`/convoy/credit/recharge/${id}`);
};

export const addWallet = (data) => {
  return api.post('/convoy/credit/recharge', { ...data });
};

export const editWallet = (data, id) => {
  return api.post(`/convoy/credit/recharge/${id}`, { ...data });
};

export const deleteWallet = (id) => {
  return api.delete(`/convoy/credit/recharge/${id}`);
};

export const getGatheringPortInformation = (data) => {
  return api.get('/convoy/cargocvge/credit', {
    params: {
      ...data,
    },
  });
};

export const getGatheringPortList = (data) => {
  return api.get('/convoy/cargocvge/credit/recharge', {
    params: {
      ...data,
    },
  });
};

export const deleteRechargePort = (id) => {
  return api.delete(`/convoy/cargocvge/credit/recharge/${id}`);
};

export const checkGatheringPortAssembly = (id) => {
  return api.get(`/convoy/cargocvge/credit/recharge/${id}`);
};

export const addRechargeGatheringPort = (data) => {
  return api.post('/convoy/cargocvge/credit/recharge', { ...data });
};

export const updateRechargeGatheringPort = (data, id) => {
  return api.put(`/convoy/cargocvge/credit/recharge/${id}`, { ...data });
};

export const getCreditGatheringPortList = (data) => {
  return api.get('/convoy/cargocvge/credit/consume', {
    params: {
      ...data,
    },
  });
};
