import api from '@common/api';
// 代垫列表
export const SubstitutecushionApiList = (params) => {
  return api.get('/convoy/supplement/get_list', {
    params,
  });
};

/**
 * @param {number} diff_box_id // 费用选项去除申请中的费用  diff_box_id箱子id
 */
export const getFilterFeeNameList = ({ box_id, need_config_id }) => {
  return api.get('/convoy/supplement/get_config', {
    params: {
      diff_box_id: box_id || undefined,
      need_config_id,
    },
  });
};
// 添加修改代垫
export const addSubstitutecushionApi = (data) => {
  return api.post('/convoy/supplement/add_cost', data);
};

// 代垫申请撤销
export const cancelSubstitutecushionApi = ({ batch_id }) => {
  return api.post('/convoy/supplement/cancel', { batch_id });
};
