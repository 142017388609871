// 公海司机搜索
export const driverSearch = (params) => ({
  url: `/convoy/driver/search`,
  params,
});
// 分派司机
export const assignDriver = (data) => ({
  url: `/convoy/dsphorder/assign`,
  data,
  method: 'post',
});
// 分派司机跳过
export const assignDriverIgnore = (data) => ({
  url: `/convoy/dsphorder/assign/ignore`,
  data,
  method: 'post',
});
// 重置司机
export const resetDriver = (data) => ({
  url: `/convoy/dsphorder/assign/cancel`,
  data,
  method: 'post',
});
// 司机类型
export const driverType = () => ({
  url: `/driver/type`,
});
// 创建司机及车辆
export const createDriverAndCar = ({
  type,
  mobile,
  name,
  car_id,
  scope,
  number,
  car_type,
  car_displace_type,
}) => ({
  url: `convoy/driver/add_with_car`,
  method: 'post',
  data: {
    type,
    mobile,
    name,
    car_id,
    scope,
    number,
    car_type,
    car_displace_type,
  },
});
// 车辆搜索
export const convoyCarSearch = (defaultParams, params) => ({
  url: `/convoy/car/search`,
  params: { ...defaultParams, ...params },
});
