import api, {
  orderDetail,
  boxChangeMessage,
  getConvoyDsphorderDriverInfo,
  getConvoyDsphorderBoxInfo,
} from '@common/api';
export const order = {
  state: {
    // 订单原始信息
    orderData: {},
    orderDetailLoading: false,
    // 变更记录
    changeMessage: null,
    changeMessageLoading: false,
    // 箱列表
    boxData: [],
    boxLoading: false,
    // 订单所有箱子对象
    allBoxData: {},
    // 订单所有箱子顺序
    allBoxSort: [],
  },
  reducers: {
    setOrderDetail: (state, dsph_order_id, detail) => {
      // 订单所有箱子对象
      return Object.assign(state, {
        orderData: detail,
        allBoxData: {
          [dsph_order_id]: detail,
        },
      });
    },
    setOrderDetailLoading: (state, orderDetailLoading) => {
      return Object.assign(state, {
        orderDetailLoading: orderDetailLoading,
      });
    },
    setChangeMessage: (state, changeMessage) => {
      return Object.assign(state, {
        changeMessage,
      });
    },
    setChangeMessageLoading: (state, changeMessageLoading) => {
      return Object.assign(state, {
        changeMessageLoading,
      });
    },
    setBoxs: (state, list) => {
      return Object.assign(state, {
        boxData: list,
      });
    },
    setBoxLoading: (state, loading) => {
      return Object.assign(state, {
        boxLoading: loading,
      });
    },
  },
  effects: (dispatch) => ({
    async getOrderDetail(dsph_order_id) {
      dispatch.order.setOrderDetailLoading(true);
      const { data, error_code, error_msg } = await api.request(
        getConvoyDsphorderDriverInfo(dsph_order_id),
      );
      dispatch.order.setOrderDetailLoading(false);
      if (error_code) return Promise.reject(error_msg);
      dispatch.order.setOrderDetail(dsph_order_id, data);
      return Promise.resolve(data);
    },
    async getOrderBoxs(dsph_order_id) {
      dispatch.order.setBoxLoading(true);
      const { data, error_code, error_msg } = await api.request(
        getConvoyDsphorderBoxInfo(dsph_order_id),
      );
      dispatch.order.setBoxLoading(false);
      if (error_code) return Promise.reject(error_msg);
      dispatch.order.setBoxs(data);
      return Promise.resolve(data);
    },
    async updateOrderInfo(dsph_order_id) {
      await this.getOrderDetail(dsph_order_id);
      await this.getOrderBoxs(dsph_order_id);
    },
    async getBoxChange(boxId) {
      dispatch.order.setChangeMessageLoading(true);
      const { data, error_code, error_msg } = await api.request(
        boxChangeMessage(boxId),
      );
      dispatch.order.setChangeMessageLoading(false);
      if (error_code) return Promise.reject(error_msg);
      dispatch.order.setChangeMessage(data);
      return Promise.resolve(data);
    },
  }),
};
