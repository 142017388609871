export const phoneReg = /^1[3456789]\d{9}$/;
export function isEmpty(value) {
  if (!value && value !== '0' && value !== 0) {
    return true;
  }
  return false;
}

export function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}
export const checkPhone = (s) => {
  if (!phoneReg.test(s)) {
    return false;
  } else {
    return true;
  }
};
export const isString = (data) => {
  return Object.prototype.toString.call(data) === '[object String]';
};

export const BoxSizeRFRHOT = (size) => {
  const code = (size + '').substring(2);
  return ['16', '18', '12'].includes(code);
};

export const BoxSizeRF = (size) => {
  const code = (size + '').substring(2);
  return '16' === code;
};
