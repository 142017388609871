import api from '@common/api';

export const getInvoiceList = (data) => {
  return api.get('/convoy/ds/invoice', {
    params: {
      ...data,
    },
  });
};

export const getInvoiceInfo = (id) => {
  return api.get(`/convoy/ds/invoice/invoice_info/${id}`);
};

export const getInvoiceInfoOrderList = (data, id) => {
  return api.get(`/convoy/ds/invoice/invoice_order_info/${id}`, {
    params: {
      ...data,
    },
  });
};

export const getInvoiceInfoOrderListFy = (data, id) => {
  return api.get(`/convoy/ds/invoice/invoice_item_info/${id}`, {
    params: {
      ...data,
    },
  });
};

export const cancleInvoice = (ids) => {
  return api.put('/convoy/ds/invoice/cancel', { ids: ids });
};

export const getYardInfoList = (data) => {
  return api.get('/resource/yard', {
    params: {
      ...data,
    },
  });
};

export const getShipList = (data) => {
  return api.get('/resource/ship/agent', {
    params: {
      ...data,
    },
  });
};

export const getExpenseList = (data) => {
  return api.get('/convoy/ds/invoice/order_check', {
    params: {
      ...data,
    },
  });
};

export const getExpenseListMoneyInfo = ({
  orderId,
  invoiceType,
  confirmStatus,
}) => {
  return api.get('/convoy/ds/invoice/order_item_info', {
    params: {
      order_id: orderId,
      invoice_type: invoiceType,
      confirm_status: confirmStatus,
    },
  });
};

export const getExpenseBase = () => {
  return api.get('/convoy/ds/invoice/info');
};

export const editExpenseBase = (data) => {
  return api.put('/convoy/ds/invoice/info', { ...data });
};

export const getExpenseItemBase = () => {
  return api.get('/convoy/ds/invoice/item_base');
};

export const applyJoinInvoice = ({ invoiceType, orderInfo }) => {
  return api.post('/convoy/ds/invoice/itemJoinInvoice', {
    invoice_type: invoiceType,
    order_info: orderInfo,
  });
};

export const expenseConfirm = ({ orderInfo }) => {
  return api.post('/convoy/ds/invoice/itemConfirm', {
    order_info: orderInfo,
  });
};

export const getInvoiceInvoiceInfo = (id) => {
  return api.get(`/convoy/ds/invoice/invoice_info/${id}`);
};
export const getInvoiceInfos = (id) => {
  return api.get(`/convoy/ds/invoice/invoice_info/${id}`);
};

export const getInvoiceOrderList = (id, data) => {
  return api.get(`/convoy/ds/invoice/invoice_order_info/${id}`, {
    params: {
      ...data,
    },
  });
};

export const getInvoiceOrderInfoList = (id, data) => {
  return api.get(`/convoy/ds/invoice/invoice_item_info/${id}`, {
    params: {
      ...data,
    },
  });
};
