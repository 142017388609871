import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import store from './store';
import App from './App';
import './index.less';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { UseRequestProvider } from 'xiangxin-design';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

const root = document.getElementById('app');
window._xiangxin_env = process.env;
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <UseRequestProvider>
        <App />
      </UseRequestProvider>
    </ConfigProvider>
  </Provider>,
  root,
);
