import api from '@common/api';

export const getRoleList = (data) => {
  return api.get('/acl/role', {
    params: data,
  });
};
export const getRoleInfo = (id) => {
  return api.get(`/client/enter/staff/${id}`);
};
export const getRoleAddInfo = (s) => {
  return api.get(`/client/enter/staff?s=${s}&enable=1`);
};
export const addRole = (data) => {
  return api.post('/acl/role', data);
};
export const updateRole = (roleId, data) => {
  return api.put(`/acl/role/${roleId}`, data);
};
export const delRole = (roleId) => {
  return api.delete(`/acl/role/${roleId}`);
};
export const getRoloList = (id) => {
  return api.get(`/acl/role/act/${id}?module_type=0`);
};
export const updateRoloList = (id, data) => {
  return api.put(`/acl/role/act/${id}`, data);
};
export const getRoleUserList = (id) => {
  return api.get(`/acl/role/user/${id}`);
};
export const delRoleList = (id) => {
  return api.delete(`/acl/role/admin/${id}`);
};
export const addRoleAdmin = (data) => {
  return api.post('/acl/role/admin', data);
};
