import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Popover, Typography } from 'antd';

import {
  LayoutHeaderWrapper,
  LayoutHeaderLead,
  LogoWrapper,
  LogoImg,
  SloganWrapper,
  SloganImg,
  LayoutHeaderTail,
  UserInfoWrapper,
  EnterpriseName,
  UserName,
  LogoutButton,
  WeappItemWrapper,
  WeappWrapper,
  WeappItemImgWrapper,
  TextWrapper,
  WeappPopoverText,
} from './styled';

import LogoImgWhiteUrl from '@assets/images/logo-white.png';
import SloganImgWhiteUrl from '@assets/images/slogan-white.png';
import gongzhonghaoUrl from '@assets/images/gongzhonghao.png';
import fangxiangWeappUrl from '@assets/images/fangxiang-weapp.png';
import jigangWeappUrl from '@assets/images/jigang-weapp.png';
import { RedirectToPassportLogout } from 'xiangxin-webC-react';

const mapState = (state) => ({
  user: state.user,
});

const LayoutHeader = ({ user }) => {
  const handleLogout = () => {
    RedirectToPassportLogout();
  };

  const gzhContent = (
    <WeappItemWrapper>
      <WeappItemImgWrapper src={gongzhonghaoUrl} alt="公众号" />
      <TextWrapper>关注公众号，及时获取订单状态</TextWrapper>
    </WeappItemWrapper>
  );

  const fxContent = (
    <WeappItemWrapper>
      <WeappItemImgWrapper src={fangxiangWeappUrl} alt="放箱小程序" />
      <TextWrapper>扫码下单</TextWrapper>
    </WeappItemWrapper>
  );

  const jgContent = (
    <WeappItemWrapper>
      <WeappItemImgWrapper src={jigangWeappUrl} alt="集港小程序" />
      <TextWrapper>扫码下单，暂仅支持司机登录</TextWrapper>
    </WeappItemWrapper>
  );

  return (
    <LayoutHeaderWrapper>
      <LayoutHeaderLead>
        <LogoWrapper>
          <LogoImg src={LogoImgWhiteUrl} />
        </LogoWrapper>
        <SloganWrapper>
          <SloganImg src={SloganImgWhiteUrl} />
        </SloganWrapper>
      </LayoutHeaderLead>
      <LayoutHeaderTail>
        <WeappWrapper>
          <Popover content={gzhContent} title="" trigger="hover">
            <WeappPopoverText>关注公众号</WeappPopoverText>
          </Popover>
          <Popover content={fxContent} title="" trigger="hover">
            <WeappPopoverText>放箱小程序</WeappPopoverText>
          </Popover>
          <Popover content={jgContent} title="" trigger="hover">
            <WeappPopoverText>集港小程序</WeappPopoverText>
          </Popover>
        </WeappWrapper>
        <UserInfoWrapper>
          <EnterpriseName>{user.enterpriseName}</EnterpriseName>
          <UserName>{user.name}</UserName>
          <LogoutButton onClick={handleLogout}>退出登录</LogoutButton>
        </UserInfoWrapper>
      </LayoutHeaderTail>
    </LayoutHeaderWrapper>
  );
};

LayoutHeader.propTypes = {
  user: PropTypes.shape({
    enterpriseName: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default connect(mapState)(LayoutHeader);
