import api from '@common/api';

export const getPorts = () => {
  return api.get('/resource/city');
};

export const getContainerSizeTypes = ({ allTypes = false, isOpen45 }) => {
  return api.get('/order/common/box_size_type', {
    params: {
      type: Number(allTypes),
      is_open45: isOpen45,
    },
  });
};

export const getShipEnterprise = ({ search }) => {
  return api.get('/order/common/ship_enter', {
    params: {
      s: search,
    },
  });
};

export const getYardList = ({ port, search, top = false }) => {
  return api.get('/resource/yard', {
    params: {
      scope: port,
      s: search,
      top: Number(top),
    },
  });
};

export const getShipAgentList = ({ search }) => {
  return api.get('/resource/ship/agent', {
    params: {
      s: search,
    },
  });
};

export const getShipAgentListByShipEnterprise = ({
  shipEnterpriseId,
  search,
}) => {
  return api.get('/dockserve/find/ship_agent', {
    params: {
      ship_enter_id: shipEnterpriseId,
      s: search,
    },
  });
};

export const getYardListByShipEnterprise = ({
  port,
  shipEnterpriseId,
  search,
  top = false,
}) => {
  return api.get('/dockserve/find/yard', {
    params: {
      scope: port,
      ship_enter_id: shipEnterpriseId,
      s: search,
      top: Number(top),
    },
  });
};

export const getWharfList = ({ portId }) => {
  return api.get('/resource/wharf/search/all', {
    params: {
      scope: portId,
    },
  });
};

export const getContainerGateInWharfList = ({ portId }) => {
  return api.get('/cargocvge/price/wharf', {
    params: {
      scope: portId,
    },
  });
};

export const getHarborList = ({ search }) => {
  return api.get('/order/common/dock', {
    params: {
      s: search,
    },
  });
};

export const getProductCategoryList = () => {
  return api.get('/order/common/getCategory');
};

export const getAdministrationPartitionList = ({ id }) => {
  const basePath = '/common/address/find';
  const path = id ? `${basePath}/${id}` : basePath;
  return api.get(path, {
    params: {
      type: -1,
    },
  });
};

/**
 * @param {number} type // 数据类型：0：全部，1:老数据(原始数据),2:新数据
 */
export const getShipNameList = ({ search, type, shipTicketId = '' }) => {
  return api.get('/order/common/ship_name', {
    params: {
      s: search,
      data_type: type,
      xx_ship_ticket_id: shipTicketId,
    },
  });
};
// 车牌号搜索
export const carSearch = (enter_id, s) => {
  return {
    url: 'common/info/search/car_info',
    params: { s, enter_id },
  };
};
// 搜索箱号、铅封号 type: 传 box 搜到的是 箱号，传 order 搜到的是 提单号）
export const dsphorderSearch = ({ s, type }) => ({
  url: '/convoy/dsphorder/search',
  params: {
    s,
    type,
  },
});

export const getDriverNumbers = ({ enterId = 0, s }) => {
  return api.get('/common/info/search/car_info', {
    params: {
      enter_id: Number(enterId),
      s,
    },
  });
};

export const getSaleUserList = (name) => ({
  url: '/client/enter/staff',
  params: {
    name,
  },
});

export const getTicketTemplate = (url) => {
  return api.get('/common/method/getimg', {
    params: {
      url,
    },
  });
};
