import api, { accountMoneyInfoApi } from '@common/api';
import { toFixed } from '@common/utils';

export const balance = {
  state: {
    loading: false,
    init: true,
  },
  reducers: {
    setBalance: (state, balance) => {
      return Object.assign(state, balance);
    },
  },
  effects: (dispatch) => ({
    async getBalance(scope) {
      dispatch.balance.setBalance({
        loading: true,
        init: false,
      });
      const { data, error_code, error_msg } = await api.get(
        accountMoneyInfoApi(scope).url,
      );
      if (error_code) return Promise.reject(error_msg);
      dispatch.balance.setBalance({
        loading: false,
        ...data,
        amount_used: toFixed(data.credit_total - data.credit_balance) || 0,
      });
      return Promise.resolve(data);
    },
  }),
};
