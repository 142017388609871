import api from '@common/api';

// 提箱-保存箱子信息
export const ladingBoxInfoSave = (box_id, data) => ({
  url: `/convoy/box/${box_id}/upload`,
  data,
  method: 'put',
});
// 提箱-提箱
export const ladingBoxSave = (segment_id) => ({
  url: `/convoy/dsphorder/type5`,
  data: { segment_id },
  method: 'put',
});
// 箱子变更信息
export const boxChangeMessage = (box_id) => ({
  url: `/convoy/box/${box_id}/change`,
});
// 箱子变更信息 已读
export const boxChangeMessageRead = (box_id) => ({
  url: `/convoy/box/${box_id}/change/read`,
  method: 'post',
});
// 装卸货照片保存 （进口是卸货，出口是装货）
export const boxProducePic = (box_id, data) => ({
  url: `/convoy/box/${box_id}/upload/pic/product`,
  data,
  method: 'put',
});
// 箱子照片保存
/**
 * data: {field_key,pics}
 * 
 * field_key
 * 	
 * 枚举: FILE_TYPE_GUOBANG,FILE_TYPE_LUOXIANG

 * 枚举备注: 过磅 落箱
 */
export const boxPicSave = (box_id, data) => ({
  url: `/convoy/box/${box_id}/pic`,
  data,
  method: 'POST',
});
// 出发
export const boxStartOff = (segment_id) => ({
  url: `/convoy/dsphorder/type1`,
  data: { segment_id },
  method: 'put',
});

// 到达工厂
export const boxArriveFactory = (segment_id) => ({
  url: `/convoy/dsphorder/type2`,
  data: { segment_id },
  method: 'put',
});
// 装货完成
export const boxLadingComplate = (segment_id) => ({
  url: `/convoy/dsphorder/type3`,
  data: { segment_id },
  method: 'put',
});
// 落箱完成
export const boxContainerReleaseComplate = (data) => ({
  url: `/convoy/dsphorder/type4`,
  data,
  method: 'put',
});
// 交还单据，箱子的操作--交还单据
export const boxReturnDocument = (segment_id, box_id) => ({
  url: `/convoy/dsphorder/back`,
  data: { segment_id, box_id },
  method: 'put',
});
// 收箱调度(获取可用收箱场地)
export const OrderLimitUseApi = (ship_ticket_id, boxs, stockInPurpose) => ({
  url: `/cargocvge/order/limit/use`,
  method: 'post',
  data: {
    ship_ticket_id: ship_ticket_id || 0,
    boxs,
    stock_in_purpose: stockInPurpose,
  },
});
// 获取航次收箱计划
export const ShipTicketOrderLimitApi = (ship_ticket_id, order_id, purpose) => ({
  url: `/cargocvge/order/limit/marge/${ship_ticket_id}`,
  params: {
    order_id,
    stock_in_purpose: purpose,
  },
});
export const boxLogisticsInformation = (box_id) => ({
  url: `/driver/order/${box_id}/line`,
});
// 新物流信息
export const boxLogisticsInformationNew = (id) => ({
  url: `/driver/order/${id}/line/v1`,
});

/**
 * * 段的操作-开始。对应原来的提箱/取挂等操作
 * @param {*} segment_id	段的id
 * @param {*} box_id	箱子id
 * @returns
 */
export const operatorStart = ({
  segment_id,
  box_id,
  box_code,
  box_ensupe,
  box_pic_msg,
  box_pic_msg2,
  net_weight,
  real_tixiang_time,
  qi_node_type,
}) => {
  switch (qi_node_type) {
    case 0: {
      return {
        url: '/convoy/dsphorder/start',
        data: {
          segment_id,
          box_id,
          box_code,
          box_ensupe,
          box_pic_msg,
          box_pic_msg2,
          net_weight,
          real_tixiang_time,
        },
        method: 'POST',
      };
    }
    case 1: {
      return {
        url: '/convoy/dsphorder/start',
        data: {
          segment_id,
          box_id,
          box_pic_msg,
          box_pic_msg2,
        },
        method: 'POST',
      };
    }
    default: {
      return {
        url: '/convoy/dsphorder/start',
        data: {
          segment_id,
          box_id,
        },
        method: 'POST',
      };
    }
  }
};

/**
 * 段的操作--到达。对应原来的工厂到达
 * @param {*} segment_id 段的id
 * @param {*} box_id 箱子id
 * @returns
 */
export const operatorArrival = ({ segment_id, box_id }) => ({
  url: '/convoy/dsphorder/daoda',
  data: {
    segment_id,
    box_id,
  },
  method: 'POST',
});

/**
 * 段的操作--装货完成。对应原来的装货完成
 * @param {*} segment_id 段的id
 * @param {*} box_id 箱子id
 * @returns
 */
export const operatorPackage = ({ segment_id, box_id }) => ({
  url: '/convoy/dsphorder/zhuanghuo',
  data: { segment_id, box_id },
  method: 'POST',
});

/**
 * 段的操作--段的完成。对应原来的落箱/摘挂/甩挂完成等操作
 * @param {*} segment_id 段的id
 * @param {*} box_id 箱子id
 * @param {*} pic_url 落箱照片
 * @returns
 */
export const operatorFinish = (data) => ({
  url: '/convoy/dsphorder/finish',
  data,
  method: 'POST',
});
