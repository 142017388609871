import { get } from 'lodash';
import api from '@common/api';

const initialState = {
  enterpriseName: null,
  mobile: null,
  name: null,
  scope: null,
  permission: {
    containerRelease: false,
    containerReleaseExport: false,
    containerReleaseImport: false,
    containerGateIn: false,
    scope: 0,
  },
  id: null,
};

export const user = {
  state: initialState,
  reducers: {
    setLoggedUser: (state, payload) => {
      state.enterpriseName = payload.enterpriseName;
      state.mobile = payload.mobile;
      state.name = payload.name;
      state.permission = payload.permission;
      state.scope = payload.scope;
      state.id = payload.id;
      return state;
    },
  },
  effects: (dispatch) => ({
    async getLoggedUser() {
      const { data, error_code, error_msg } = await api.get(
        '/user/account/info',
      );
      if (error_code) return Promise.reject(error_msg);
      const convoyUserPermission = data.convoyPermissionOfAttributes;
      dispatch.user.setLoggedUser({
        enterpriseName: data.enter_name,
        mobile: data.mobile,
        name: data.name,
        scope: data.scope,
        permission: {
          containerRelease: get(convoyUserPermission, 'permissionFX', false),
          containerReleaseExport: get(
            convoyUserPermission,
            'permissionFXExport',
            false,
          ),
          containerReleaseImport: get(
            convoyUserPermission,
            'permissionFXImport',
            false,
          ),
          containerGateIn: get(convoyUserPermission, 'permissionJG', false),
          scope: data.scope,
        },
        id: data.id,
      });
      return Promise.resolve(data);
    },
  }),
};
