import { getMenuList } from '@common/api';

export const menu = {
  state: [],
  reducers: {
    setMenu: (state, menuData) => {
      return Object.assign(state, menuData);
    },
  },
  effects: (dispatch) => ({
    async getMenu() {
      const { data, error_code, error_msg } = await getMenuList();
      if (error_code) return Promise.reject(error_msg);
      dispatch.menu.setMenu(data.menu);
      return Promise.resolve(data);
    },
  }),
};
