import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import { useSelector } from 'react-redux';
import LayoutHeader from './Header';
import Breadcrumb from './Breadcrumb';
import {
  LayoutWrapper,
  LayoutInner,
  LayoutContent,
  LayoutSidebarAffix,
  SystemName,
} from './styled';

const { Sider } = Layout;
const { SubMenu, Item: MenuItem } = Menu;

const DefaultLayout = ({ children }) => {
  const { pathname } = useLocation();
  const menu = useSelector((state) => state.menu);
  const [openKeys, setOpenKeys] = React.useState([]);
  const [selectedKeys, setSelectedKeys] = React.useState([]);

  const menuActiveStatus = (() => {
    const selectedKeys = [];
    const openKeys = [];

    menu.forEach((parent) => {
      parent.menu.forEach((child) => {
        const matched = matchPath(pathname, {
          path: child.url,
        });
        if (matched) {
          openKeys.push(parent.name);
          selectedKeys.push(child.url);
        }
      });
    });

    return {
      openKeys,
      selectedKeys,
    };
  })();
  React.useEffect(() => {
    setOpenKeys(menuActiveStatus.openKeys);
    setSelectedKeys(menuActiveStatus.selectedKeys);
  }, [pathname, menu]);

  const handleMenuOpenChange = (newOpenKeys) => {
    setOpenKeys([...newOpenKeys]);
  };

  return (
    <LayoutWrapper>
      <LayoutHeader />
      <LayoutInner>
        <LayoutSidebarAffix>
          <Sider theme="light">
            <SystemName>箱信</SystemName>
            <Menu
              mode="inline"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onOpenChange={handleMenuOpenChange}
            >
              {menu.map((parent) => {
                return (
                  <SubMenu title={parent.name} key={parent.name}>
                    {parent.menu.map((child) => {
                      return (
                        <MenuItem key={child.url}>
                          <Link to={child.url}>{child.name}</Link>
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                );
              })}
            </Menu>
          </Sider>
        </LayoutSidebarAffix>
        <Layout>
          <Breadcrumb />
          <LayoutContent>{children}</LayoutContent>
        </Layout>
      </LayoutInner>
    </LayoutWrapper>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultLayout;
