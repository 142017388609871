export const IMPORT_AND_EXPORT_TYPES = {
  Export: 1,
  Import: 2,
};

export const IMPORT_AND_EXPORT_TYPES_TEXT = {
  [IMPORT_AND_EXPORT_TYPES.Export]: '出口',
  [IMPORT_AND_EXPORT_TYPES.Import]: '进口',
};

export const IMPORT_AND_EXPORT_TYPE_OPTIONS = [
  {
    label: IMPORT_AND_EXPORT_TYPES_TEXT[IMPORT_AND_EXPORT_TYPES.Export],
    value: IMPORT_AND_EXPORT_TYPES.Export,
  },
  {
    label: IMPORT_AND_EXPORT_TYPES_TEXT[IMPORT_AND_EXPORT_TYPES.Import],
    value: IMPORT_AND_EXPORT_TYPES.Import,
  },
];

export const TRADE_TYPES = {
  Foreign: 1,
  Domestic: 2,
};

export const TRADE_TYPES_TEXT = {
  [TRADE_TYPES.Foreign]: '外贸',
  [TRADE_TYPES.Domestic]: '内贸',
};

export const TRADE_TYPE_OPTIONS = [
  {
    label: TRADE_TYPES_TEXT[TRADE_TYPES.Foreign],
    value: TRADE_TYPES.Foreign,
  },
  {
    label: TRADE_TYPES_TEXT[TRADE_TYPES.Domestic],
    value: TRADE_TYPES.Domestic,
  },
];
export const LOADING_OR_UNLOADING = {
  出口: '装货',
  进口: '卸货',
};
export const LOADING_OR_UNLOADING2 = {
  出口: '装箱',
  进口: '卸货',
};

export const ENTER_OBJETIVE_OPTIONS = [
  {
    label: '代集港',
    value: 1,
  },
  {
    label: '暂落',
    value: 2,
  },
  {
    label: '转栈',
    value: 3,
  },
];
