import React from 'react';
import PropTypes from 'prop-types';
import { getRouteAllPath, RouteConfig } from '../../Routes';
import { useLocation, Link, matchPath } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { useLocalStorageState } from 'ahooks';

const BreadcrumbComponent = () => {
  const { pathname } = useLocation();
  const [allRoutePath, setAllRoutePath] = React.useState({});
  const menuData = useSelector((state) => state.menu);
  const [menuPaths, setMenuPaths] = React.useState(null);
  const [breadcrumb, setBreadcrumb] = useLocalStorageState(
    'convoy-helper-breadcrumn',
    [],
  );
  React.useEffect(() => {
    let menuPathData = menuPaths;
    let allRoutePathData = {};
    let breadcrumbData = [...breadcrumb];
    // 更新所有菜单地址数据
    if (!menuPaths && menuData && menuData.length) {
      menuPathData = {};
      getAllMenuPath(menuData, menuPathData);
      setMenuPaths(menuPathData);
    }
    // 根据路由配置获取面包屑及所有路由地址数据
    const { pathData, breadcrumbData: newBreadcrumb } = breadcrumbHandel();
    allRoutePathData = pathData;
    // 判断当前页面是不是菜单页，菜单页将重置面包屑
    if (menuPathData && menuPathData[pathname]) {
      breadcrumbData = newBreadcrumb;
    } else {
      // 如果面包屑不存在就赋值
      if (!breadcrumbData || !breadcrumbData.length) {
        breadcrumbData = newBreadcrumb;
      }
      // 查找当前面包屑是否包含当前页面
      let index = -1;
      breadcrumbData.find((item, i) => {
        const match = matchPath(pathname, {
          path: item.path,
          exact: true,
          strict: false,
        });
        if (match) {
          index = i;
          return true;
        }
      });
      // 如果包含就去掉
      if (index > -1) {
        breadcrumbData = breadcrumbData.slice(0, index);
      }
      // 判断当前页面地址是否是已配置面包屑的地址
      if (allRoutePathData) {
        let mathPath = null;
        Object.keys(allRoutePathData).some((key) => {
          let matchData = matchPath(pathname, {
            path: key,
            exact: true,
            strict: false,
          });
          if (matchData) mathPath = matchData.path;
          return matchData;
        });
        if (mathPath) {
          const newItem = allRoutePathData[mathPath];
          // 如果当前最后一条配置了replace，且要插入的这一条也配置了replace，则去掉当前最后一条
          if (
            breadcrumbData.length &&
            breadcrumbData[breadcrumbData.length - 1].replace &&
            newItem.replace
          ) {
            breadcrumbData = breadcrumbData.slice(0, breadcrumbData.length - 1);
          }
          breadcrumbData.push({
            ...newItem,
            path: pathname,
            search: location.search,
          });
        } else {
          breadcrumbData = [];
        }
      }
    }
    setBreadcrumb(breadcrumbData);
  }, [pathname, menuData]);
  function breadcrumbHandel() {
    let pathData = {};
    let breadcrumbData = [];
    getRouteAllPath(RouteConfig, '', pathData, breadcrumbData);
    return { pathData, breadcrumbData: breadcrumbData.reverse() };
  }
  function getAllMenuPath(menus, result) {
    menus.reduce((res, item) => {
      const { menu, url } = item;
      if (menu) {
        getAllMenuPath(menu, res);
      }
      if (url) {
        res[url] = item;
      }
      return res;
    }, result);
  }
  return (
    <>
      {breadcrumb && !!breadcrumb.length && (
        <div
          style={{
            boxShadow: '0px 0px 4px #CCCCCC',
            padding: '18px 20px',
            background: '#fff',
          }}
          className="verticalCenter"
        >
          <Breadcrumb>
            {breadcrumb.map((item, index) => {
              const { title, path = '', search = '' } = item;
              return (
                <Breadcrumb.Item key={index}>
                  {path && index != breadcrumb.length - 1 && (
                    <Link to={`${path}${search}`}>{title}</Link>
                  )}
                  {(!path || index == breadcrumb.length - 1) && <>{title}</>}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </div>
      )}
    </>
  );
};
// BreadcrumbComponent.propTypes = {
//   selectedKeys: PropTypes.array,
// };
export default BreadcrumbComponent;
